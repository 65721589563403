<template>
  <div>
    <div class="main-hero-wrap pb-0">
      <header>
        <div class="container">
          <div class="logo">
            <a href="#">
              <img src="../../assets/logoipsum.svg" alt="" />
            </a>
          </div>
          <nav>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <div class="c-badge">Sale</div>
                <a href="#">Pricing</a>
              </li>
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </nav>
          <div class="header-buttons">
            <a href="#" class="basket">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-person"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
                />
              </svg>
              User Panel
            </a>
            <button class="hamburger-btn">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 4.375H2.5C2.15833 4.375 1.875 4.09167 1.875 3.75C1.875 3.40833 2.15833 3.125 2.5 3.125H17.5C17.8417 3.125 18.125 3.40833 18.125 3.75C18.125 4.09167 17.8417 4.375 17.5 4.375Z"
                  fill="white"
                />
                <path
                  d="M17.4999 8.54175H9.60828C9.26661 8.54175 8.98328 8.25841 8.98328 7.91675C8.98328 7.57508 9.26661 7.29175 9.60828 7.29175H17.4999C17.8416 7.29175 18.1249 7.57508 18.1249 7.91675C18.1249 8.25841 17.8416 8.54175 17.4999 8.54175Z"
                  fill="white"
                />
                <path
                  d="M17.5 12.7083H2.5C2.15833 12.7083 1.875 12.4249 1.875 12.0833C1.875 11.7416 2.15833 11.4583 2.5 11.4583H17.5C17.8417 11.4583 18.125 11.7416 18.125 12.0833C18.125 12.4249 17.8417 12.7083 17.5 12.7083Z"
                  fill="white"
                />
                <path
                  d="M17.4999 16.875H9.60828C9.26661 16.875 8.98328 16.5917 8.98328 16.25C8.98328 15.9083 9.26661 15.625 9.60828 15.625H17.4999C17.8416 15.625 18.1249 15.9083 18.1249 16.25C18.1249 16.5917 17.8416 16.875 17.4999 16.875Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>
    </div>

    <div class="panel-page-menu">
      <div class="container">
        <div class="menu-buttons">
          <router-link to="/">Dashboard</router-link>
          <router-link to="/shipping" class="active">Couriers & Services</router-link>
          <router-link to="/orders">Orders</router-link>
          <router-link to="/">Balance ($0.00)</router-link>
          <router-link to="/">Address Book</router-link>
          <router-link to="/">Support</router-link>
        </div>
      </div>
    </div>

    <div class="panel-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="panel-table">
              <div class="head">
                <h3>Couriers & Services</h3>
                <div class="d-flex"></div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>CODE</th>
                    <th>NAME</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(courier, i) in couriers" :key="i">
                    <td>
                      <p>{{ courier.code }}</p>
                    </td>
                    <td>
                      <p>{{ courier.name }}</p>
                    </td>
                    <td>
                      <button @click="viewDetails(i)">View Details</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-modal-bg" @click="showModal = false" v-if="showModal"></div>
    <div class="c-modal" v-if="showModal">
      <div class="c-modal-head">
        <div class="title">Courier Details</div>
        <button @click="showModal = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
            />
          </svg>
        </button>
      </div>
      <div class="c-modal-content">
        <!-- {{ courierDetails }} -->
        <ul>
          <div class="row">
            <div class="col-4">
              <li>
                <strong>Code</strong>
                <span>{{ courierDetails.code }}</span>
              </li>
            </div>
            <div class="col-4">
              <li>
                <strong>Name</strong>
                <span>{{ courierDetails.name }}</span>
              </li>
            </div>
            <div class="col-4">
              <li>
                <strong>Properties</strong>
                <span>{{
                  courierDetails.properties ? courierDetails.properties : "-"
                }}</span>
              </li>
            </div>
          </div>
        </ul>
        <div class="services-list">
          <h4 class="mb-3">Services</h4>
          <div
            class="service row"
            v-for="(service, i) in courierDetails.services"
            :key="i"
          >
            <div class="col-6">
              <strong>Code</strong>
              <span>{{ service.code }}</span>
            </div>
            <div class="col-6">
              <strong>Courier Code</strong>
              <span>{{ service.courier_code }}</span>
            </div>
            <div class="col-6">
              <strong>Description</strong>
              <span>{{ service.description }}</span>
            </div>
            <div class="col-6">
              <strong>Is Domestic</strong>
              <span>{{ service.is_domestic ? "YES" : "NO" }}</span>
            </div>
            <div class="col-6">
              <strong>Max Weight</strong>
              <span>{{ service.max_weight }}</span>
            </div>
            <div class="col-6">
              <strong>Name</strong>
              <span>{{ service.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />
    <br />

    <div class="newsletter">
      <div class="container">
        <div class="nl-box">
          <div class="text">
            <h3>Try it out now!</h3>
            <p>Start Shipping!</p>
          </div>
          <div class="buttons">
            <a href="#"> Get Started </a>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="footer-info">
              <img src="images/logoipsum.svg" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Company</strong>
                  <a href="#">Blog</a>
                  <a href="#">Help Center</a>
                  <a href="#">Sales Tools Catalog</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Support </strong>
                  <a href="#">Partners</a>
                  <a href="#">Policy</a>
                  <a href="#">Programs</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Get in touch</strong>
                  <a href="#">Support</a>
                  <a href="#">About us</a>
                  <a href="#">FAQ</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>© 2023 All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import axios from "axios";
export default {
  components: {
    BAlert,
    BSpinner,
  },
  data() {
    return {
      errors: [],
      couriers: [],
      showModal: false,
      courierDetails: {},
    };
  },
  mounted() {
    this.getCouriers();
  },
  methods: {
    getCouriers() {
      axios
        .get(`shipping/get-couriers`)
        .then((res) => {
          console.log(res.data);
          this.couriers = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    viewDetails(index) {
      this.showModal = true;
      this.courierDetails = this.couriers[index];
    },
  },
};
</script>

<style lang="scss" scoped></style>
